#root .Step {
  opacity: 1.0;
  position: relative;
  flex: 1 1 20.00%;
  transition: opacity 150ms ease-in-out 0ms;

  h4 {
    font-family: 'sofi-ex', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: var(--secondary);
    font-size: 18px;

    + * {
      margin-top: 20px;
    }
  }

  p {
    .note {
      font-size: 13px;
    }
  }

  .form-label-group {
    position: relative;
    width: 100.0%;
    margin: 0;

    input:not(:placeholder-shown) ~ label {
      color: rgba(0,0,0,0.6);
      height: 15px;
      width: auto;
      top: -8px;
      left: 8px;
      padding: 0 8px;
      font-size: 14px;
      background: white;
    }

    > label {
      position: absolute;
      top: 15px;
      left: 0;
      width: calc(100.0% - 30px);
      margin-top: 0;
      cursor: text;
      background: transparent;
      transition: all 100ms ease-in-out;
    }

    > input, > label {
      font-size: 16px;
      padding: 0 15px;
      height: 48px;
    }
  }

  p .btn {
    display: inline-block;
    width: auto;
    margin: 1rem;
  }

  .btn {
    width: 100%;
    margin: 30px 0 0;
    font-family: 'sofi-ex', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    background-color: var(--primary);
    color: var(--primaryTextInverted);
    display: block;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &[disabled] {
      opacity: 0.3;
    }

    + .btn {
      margin-top: 15px;
    }

    &:hover:not([disabled]) {
      background-color: var(--secondary);
      color: var(--secondaryText);

      > small {
        color: var(--secondaryText);
      }
    }

    > small {
      color: var(--primaryTextInverted);
    }

    + p {
      margin-top: 30px;
    }
  }

  .confirmBanner {
    width: 100px;
    height: auto;
    margin: 0 auto 20px;
  }

  .link {
    display: inline-block;
    padding-left: 24px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 100.0%;

    @media only screen {
      &.web {
        background-image: url(../assets/images/globe_icon.svg);
      }

      &.mail {
        background-image: url(../assets/images/mail_outline.svg);
      }

      &.tel {
        background-image: url(../assets/images/phone.svg);
      }
    }
  }

  table {
    width: 100.0%;
    border-collapse: collapse;
    border-spacing: 0;

    &.summary {
      td:last-child {
        width: 66%;
      }
    }

    td {
      &.right {
        text-align: right;
      }
    }

    .mlo {
      td {
        padding: 10px 0;
        border-top-width: 1px;
      }
    }

    td {
      padding-bottom: 10px;
    }
  }

  > div {
    position: relative;
    width: 100.0%;
    height: 100.0%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;

    > div {
      position: relative;
      margin: 40px 30px;

      &:nth-child(1) {
        flex: 1 1 450px;
      }

      &:nth-child(2) {
        flex: 1 1 150px;
      }
    }
  }
}
