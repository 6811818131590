#root .ConfirmCancelModal {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100.0%;
  height: 100.0%;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;

  .desktop, .mobile {
    width: 160px;
    height: 42px;
    margin: 30px auto 10px;
    border-radius: 3px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    overflow: hidden;

    > a {
      font-size: 14px;
      text-transform: uppercase;
      flex: 1 1 10.00%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: center;
      font-family: 'sofi-ex', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: normal;

      &:first-child {
        background-color: var(--secondary);
        color: var(--secondaryText);
      }

      &:last-child {
        background-color: var(--alert);
        color: white;
      }
    }

    &.desktop {
      display: flex;
    }
  }

  > div {
    position: relative;
    z-index: 4;
    width: 90.00%;
    max-width: 860px;
    margin-top: 60px;
    border-width: 2px;
    border-radius: 6px;
    background: white;
    overflow: hidden;
    max-height: 600px;

    > div {
      width: calc(100.0% - 80px + 16px);
      padding: 30px 50px 30px 30px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      position: relative;
      height: calc(100.0% - 60px);
    }
  }

  .control {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    width: 30px;
    overflow: hidden;

    .ico {
      opacity: 0.40;
      display: inline-block;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;

      &:hover {
        opacity: 1.00;
      }

      &.close {
        background-image: url(../assets/images/highlight_off.svg);
        background-size: 30px;
      }
    }
  }

  &:before {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100.0%;
    height: 100.0%;
    background: white;
    opacity: 0.75;
    content: '';
  }
}
