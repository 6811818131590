#root div {
  .reservationSummary {
    max-width: 900px;

    .flexBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .reservationDetails, .customerDetails, .dealerInfos {
        flex: 1 0 250px;
      }
    }

    .innerTable td {
      border-width: 0;
      padding: 0 0 10px 0;
    }

    a {
      &.link {
        max-height: 20px;
      }
    }

    td {
      vertical-align: top;

      &:first-child {
        min-width: 220px;
        max-width: 250px;
      }
    }

    .dealerInfos {
      max-width: 400px;
    }

  }

}
