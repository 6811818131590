.hpm-browserNotSupported {

  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  font-family: 'Helvetica Neue', 'Arial', serif;
  text-align: center;
  margin: 20px 0;

  .hpm-notSupportedHeadline {
	font-size: 40px;
  }

  .hpm-notSupportedText {
	margin-top:10px;
	font-size: 14px;
  }

  .hpm-supportedBrowsers {
	$icon-size: 100px;
	$padding: 20px;

	margin-top: 30px;
	display: flex;
	justify-content: center;

		.hpm-edge {
		  background-image: url("./assets/edge.png");
		  background-repeat: no-repeat;
		  background-position: center;
		  height: $icon-size;
		  width: $icon-size;
		  background-size: $icon-size;
		  padding: $padding;

		}
		.hpm-firefox {
		  background-image: url("./assets/firefox.png");
		  background-repeat: no-repeat;
		  background-position: center;
		  height: $icon-size;
		  width: $icon-size;
		  background-size: $icon-size;
		  padding: $padding;
		}
		.hpm-chrome {
		  background-image: url("./assets/chrome.png");
		  background-repeat: no-repeat;
		  background-position: center;
		  height: $icon-size;
		  width: $icon-size;
		  background-size: $icon-size;
		  padding: $padding;
		}
  }

}
