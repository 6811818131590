#root {
  .printBanner {
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    > img {
      height: auto;
      width: 100%;
    }
  }

  .Content {
    position: relative;
    width: 100%;
    margin-top: 30px;
    margin-left: 0;
    border-top-width: 1px;
    transition: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;

    &.CarNotAvailable {
      border-top-width: 0;
    }
  }
}
