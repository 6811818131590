@import "../helper/queries";

#root {
  font-family: 'sofi-li', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  width: 100.0%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  line-height: 1.2;

  .rtl {
    direction: rtl;
  }

  small {
    font-size: 13px;
  }


  .onlyPrint {
    display: none !important;
  }

  @media only print {
    &#root {
      position: relative;
      padding: 5rem;
      width: calc(100% - 10rem);
    }

    .link {
      padding-left: unset !important;
    }

    .onlyPrint {
      display: block !important;
    }
    .noPrint {
      display: none !important;
    }
  }

  * {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    border-style: solid;
    transition: all 150ms ease-in-out 0ms;
    color: black;
    border-color: var(--border);
  }

  hr {
    width: 100.0%;
    margin: 20px 0;
    border-top-width: 1px;
  }

  strong {
    font-family: 'sofi-bo', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.05em;
    color: var(--secondary);
  }

  select {
    width: 100.0%;
    height: 48px;
    background-repeat: no-repeat;
    background-position: top 50% right 9px;
    background-size: 24px;
  }

  input, select, .semibtn {
    font-family: 'sofi-li', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  input[type=checkbox], input[type=radio] {
    display: none;
  }

  input[type=checkbox].check[value="on"] + label:before,
  input[type=radio].check[value="on"] + label:before {
    background-image: url(../assets/images/check_white.svg);
    background-color: black;
  }

  input[type=checkbox].check + label,
  input[type=radio].check + label {
    position: relative;
    height: 30px;
    margin: 2px 0;
    padding-left: 45px;
    font-size: 14px;
    line-height: 1.20;
    border-width: 0;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      box-shadow: inset 0 0 0 2px var(--secondary);
      border-radius: 100.0%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
    }
  }

  a, label {
    text-decoration: none;
    cursor: pointer;

    > a {
      color: var(--primary);
    }
  }

  h3 {
    font-family: 'sofi-ex', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    color: var(--primaryTextInverted);
    margin-bottom: 14px;
  }

  h4 {
    font-family: 'sofi-ex', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;

    + * {
      margin-top: 20px;
    }
  }

  .btn {
    display: inline-block;
    margin: 20px auto 0;
    font-family: 'sofi-ex', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
  }

  select, input, textarea {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input {
    width: calc(100.0% - 30px);
    height: 42px;
    border-radius: 3px;
  }

  input, select {
    color: var(--secondary);
    box-shadow: inset 0 0 0 1px var(--border);
    background: white;
    font-size: 16px;
    padding: 0 15px;
    border-radius: 3px;
  }

  .center {
    text-align: center;
  }

  p {
    line-height: 1.4;

    > a {
      color: var(--primary);
    }

    + p {
      margin-top: 10px;
    }
  }

  div {
    margin: 0;
    width: unset;
  }

  img, ul, video {
    display: block;
  }
}
