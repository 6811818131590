#root {
  .paymentPendingPage {

    a {
      text-decoration: none;
      color: var(--primaryText);
    }

    .center {
      display: block;
    }
  }
}

