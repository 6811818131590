#root .vehicleDisplay {
  position: relative;
  width: 100.0%;
  margin: 14px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;

  .dealer {
    flex: 1 0 200px;
    margin: 5px 60px 5px 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    p {
      padding-left: 36px;
      background-image: url(../assets/images/location_on_sofa-dark.svg);
      background-repeat: no-repeat;
      background-position: top left;
      background-size: 30px;
    }
  }

  .vehicleOptions, .vehicleColors {
    li {
      list-style-type: none;
      margin-left: 1em;
      &:before {
        content: "•";
        color: black;
        display: inline-block;
        width: 1em;
        margin-left: -1em
      }
    }
  }

  > div {
    flex: 0 0 250px;
    margin: 0 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    > div {
      flex: 1 0 22%;
      margin: 5px 60px 5px 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      max-width: calc(33% - 70px);

      @media (max-width: 700px) {
        &.dealer {
          display: none;
        }
      }


      &:last-child {
      }

      > span {
        display: block;
        width: 100.0%;
        text-align: center;

        &:nth-child(1) {
          font-family: 'sofi-ex', Arial, Helvetica, sans-serif;
          font-style: normal;
          font-weight: normal;
          background-color: var(--primary);
          color: var(--primaryTextInverted);
          font-size: 18px;
          margin-bottom: 10px;
          padding: 8px 0 5px;
        }

        &:nth-child(2) {
          text-decoration: line-through;
        }
      }

    }

    > img {
      width: 100.0%;
      height: auto;
    }

    &:nth-child(2) {
      flex: 1 1 200px
    }
  }
}
