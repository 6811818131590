#root .stepper {
  position: relative;
  width: 98.0%;
  height: 50px;
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;

  &.step1 {
    .stepPoint:not(:nth-child(n+2)) > div:first-child {
      background-color: var(--primary);
      color: var(--primaryTextInverted);
    }

    .stepLine > div {
      width: 0;
    }
  }

  &.step2 {
    .stepPoint:not(:nth-child(n+3)) > div:first-child {
      background-color: var(--primary);
      color: var(--primaryTextInverted);
    }

    .stepLine > div {
      width: 25%;
    }
  }

  &.step3 {
    .stepPoint:not(:nth-child(n+4)) > div:first-child {
      background-color: var(--primary);
      color: var(--primaryTextInverted);
    }

    .stepLine > div {
      width: 50%;
    }
  }

  &.step4 {
    .stepPoint:not(:nth-child(n+5)) > div:first-child {
      background-color: var(--primary);
      color: var(--primaryTextInverted);
    }

    .stepLine > div {
      width: 75%;
    }
  }

  &.step5 {
    .stepPoint:not(:nth-child(n+6)) > div:first-child {
      background-color: var(--primary);
      color: var(--primaryTextInverted);
    }

    .stepLine > div {
      width: 100%;
    }
  }

  .stepPoint {
    position: relative;
    z-index: 2;
    width: 40px;

    > div {
      &:first-child {
        background-color: var(--secondary);
        color: var(--secondaryText);
        top: 0;
        left: calc(50.00% - 12.5px);
        width: 25px;
        height: 25px;
        border-radius: 100.0%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: absolute;
        text-align: center;
        font-size: 12px;
        line-height: 1;
        text-transform: uppercase;
      }

      &:last-child {
        position: absolute;
        text-align: center;
        font-size: 12px;
        line-height: 1;
        text-transform: uppercase;
        bottom: -0.7rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 2rem;
      }
    }
  }

  .stepLine {
    position: absolute;
    top: 9px;
    left: 30px;
    width: calc(100.0% - 60px);
    height: 7px;
    background-color: var(--secondary);
    color: var(--secondaryText);

    > div {
      background-color: var(--primary);
      color: var(--primaryTextInverted);
      position: absolute;
      top: 0;
      left: 0;
      height: 100.0%;
      width: 0;
    }
  }
}
