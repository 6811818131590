#root div {
  .Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    flex-flow: row wrap;

    .ball-beat {
      top: 50%;
      left: 50%;
      position: absolute;
      flex: 1 1 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      transform: translate(-50%, -50%);

      > div {
        border-radius: 100%;
        text-align: center;
        width: 15px;
        height: 15px;
      }
    }
  }
}
