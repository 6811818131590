#root .counter {
  display: block;
  width: 100.0%;
  margin-top: 10px;
  text-align: center;

  > span {
    display: inline-block;
    margin-left: 0.50em;
    color: var(--alert);
  }
}

@media only print {
  #root .counter {
    display: none;
  }
}
