#root {

  .termsAndConditionsBox {
    margin-top: 24px;

    .termsAndConditions {
      white-space: pre-wrap;
    }

    a {
      text-decoration: underline;
    }
  }


  .procedereHint {
    margin-top: 15px;
    margin-bottom: -15px;
    position: relative;
    font-size: 14px;
    line-height: 1.20;
    border-width: 0;
  }

}
